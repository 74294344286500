import { TrackedButton } from '@flock/shared-ui'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import Fade from 'react-reveal/Fade'
import SectionLayout from '../SectionLayout'
import { HeroPresentationalProps } from './heroTypes'

const HeroDesktop = (props: HeroPresentationalProps) => {
  const {
    title,
    description,
    imgSrc,
    caption,
    showCallCta,
    otherCalendlyProps,
    onOpenCalendly,
  } = props

  return (
    <SectionLayout
      name="hero-section"
      backgroundColor="midnightBlue.main"
      gridSx={{
        alignItems: 'center',
      }}
    >
      <Grid item xs={6} display="flex" flexDirection="column" gap="32px">
        <Typography variant="h1" color="white.main">
          {title}
        </Typography>
        <Typography
          variant="p1"
          color="white.main"
          sx={{
            fontSize: !title ? '42px' : '22px',
            lineHeight: !title ? '130%' : undefined,
            fontWeight: 300,
          }}
        >
          {description}
        </Typography>
        {showCallCta && (
          <Box>
            <TrackedButton
              variant="primary"
              onClick={onOpenCalendly}
              size="small"
              {...otherCalendlyProps}
            >
              Schedule a call
            </TrackedButton>
          </Box>
        )}
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5} display="flex" alignItems="center">
        <Fade duration={500} distance="3rem" right>
          <Box width="448px" height="380px" position="relative">
            {caption && (
              <Box
                position="absolute"
                sx={{
                  backgroundColor: 'white.main',
                  bottom: '10px',
                  right: '10px',
                  padding: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '8px',
                }}
              >
                <Typography variant="c2">{caption}</Typography>
              </Box>
            )}

            <Box
              component="img"
              sx={{
                borderRadius: '16px',
              }}
              src={imgSrc}
              width="448px"
              height="380px"
            />
          </Box>
        </Fade>
      </Grid>
    </SectionLayout>
  )
}

export default HeroDesktop
