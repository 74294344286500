import React from 'react'
import FaqSection from '../components/FaqComponents/FaqContent'
import Hero from '../components/SharedComponents/Hero/Hero'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import ScheduleCall from '../components/SharedComponents/ScheduleCall'
import { useCalendly } from '../components/utils'
import { CALENDLY_LANDING_URL } from '../constants'
import FaqImage from '../images/faq-hero.webp'

const pageTitle = 'Overmoon | FAQ'
const pageDescription = 'Questions? We have answers.'

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const FAQ = () => {
  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'faq',
    scheduleUrl: CALENDLY_LANDING_URL as string,
  })
  return (
    <PageWrapper title={pageTitle} trackingName="faq">
      <ScheduleCall {...otherCalendlyProps} />
      <Hero
        title="Questions? We have answers."
        description="If you can’t find what you are looking for below set up a free consultation with our advisors today."
        imgSrc={FaqImage}
        caption="Sevierville, TN"
        showCallCta
        onOpenCalendly={onOpenCalendly}
      />

      <FaqSection />
    </PageWrapper>
  )
}

export default FAQ
