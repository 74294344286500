import { TrackedButton } from '@flock/shared-ui'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import SectionLayout from '../SectionLayout'
import { HeroPresentationalProps } from './heroTypes'

const HeroMobile = (props: HeroPresentationalProps) => {
  const {
    title,
    description,
    imgSrc,
    showCallCta,
    onOpenCalendly,
    otherCalendlyProps,
    descriptionVariant,
  } = props

  return (
    <SectionLayout
      name="hero-section"
      backgroundColor="midnightBlue.main"
      backgroundImage={imgSrc}
      backgroundFull
    >
      <Grid item xs={12} display="flex" flexDirection="column" gap="32px">
        <Typography variant="h1m" color="white.main">
          {title}
        </Typography>
        <Typography variant={descriptionVariant || 'p1m'} color="white.main">
          {description}
        </Typography>
        {showCallCta && (
          <Box>
            <TrackedButton
              variant="primary"
              onClick={onOpenCalendly}
              size="small"
              {...otherCalendlyProps}
            >
              Schedule a call
            </TrackedButton>
          </Box>
        )}
      </Grid>
      <Grid item xs={1} />
    </SectionLayout>
  )
}

export default HeroMobile
