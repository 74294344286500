import React from 'react'
import HeroDesktop from './HeroDesktop'
import HeroTablet from './HeroTablet'
import HeroMobile from './HeroMobile'
import useHero from './useHero'
import { HeroProps } from './heroTypes'

const Hero = (props: HeroProps) => {
  const presentationalProps = useHero(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <HeroMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <HeroTablet {...presentationalProps} />
  }
  return <HeroDesktop {...presentationalProps} />
}

export default Hero
