import {
  Sidebar,
  SidebarItem,
  TrackedAccordion,
  TrackedLink,
} from '@flock/shared-ui'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TrackedAccordionProps } from '@flock/shared-ui/src/components/TrackedAccordion/Accordion'
import { OM_DEFAULT_DISTRIBUTION_FACTOR } from '@flock/utils'

import { prettyPrintStates, scrollIntoView, useInView } from '../utils'
import SectionLayout from '../SharedComponents/SectionLayout'
import { PORTFOLIO_PATH } from '../../routeConstants'

const sectionIds = ['exchange', 'ownership', 'about-overmoon']

type FaqProps = {
  sideBarItems?: {
    id: string
    title: string
    description: string
  }[]
  faqSubsections?: {
    id?: string
    elements?: TrackedAccordionProps[]
  }[]
}

const FaqSection = (props: FaqProps) => {
  const { sideBarItems, faqSubsections } = props
  const [activeStep, setActiveStep] = useState(0)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const currentSection = useInView(sectionIds)

  const onChange = (newTab: number) => {
    scrollIntoView(sectionIds[newTab])
  }

  useEffect(() => {
    setActiveStep(sectionIds.indexOf(currentSection))
  }, [currentSection])

  return isMobile ? (
    <>
      {faqSubsections?.map((faqSubsection, index) => {
        const sidebarInfo = sideBarItems![index]
        return (
          <SectionLayout
            name="faq"
            backgroundColor="gray1.main"
            key={sidebarInfo.title || ''}
          >
            <Grid item xs={12} id={sidebarInfo.id}>
              <Typography
                variant="h2"
                textAlign="left"
                sx={{
                  mb: '16px',
                }}
              >
                {sidebarInfo.title}
              </Typography>
              <Typography variant="h4" textAlign="left">
                {sidebarInfo.description}
              </Typography>
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                gap="32px"
                pt="30px"
              >
                {faqSubsection.elements?.map(
                  (element: TrackedAccordionProps) => (
                    <TrackedAccordion {...element} />
                  )
                )}
              </Box>
            </Grid>
          </SectionLayout>
        )
      })}
    </>
  ) : (
    <SectionLayout
      name="faq"
      backgroundColor="gray1.main"
      verticalPadding="36px"
    >
      {faqSubsections?.map((faqSubsection, index) => (
        <>
          {index === 0 ? (
            <Grid
              sx={{
                position: 'sticky',
                top: '3%',
                height: 'fit-content',
                zIndex: 999,
              }}
              item
              xs={5}
              display="flex"
              justifyContent="flex-end"
            >
              <Box width="100%" pt="72px" pb="80px">
                <Sidebar
                  items={sideBarItems as SidebarItem[]}
                  onChange={onChange}
                  selected={activeStep}
                />
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              xs={5}
              sx={
                index % 2 === 1
                  ? {
                      position: 'relative',
                      backgroundColor: 'gray1.main',
                      '&:before': {
                        content: '""',
                        position: 'absolute',
                        /* fill vertically */
                        top: 0,
                        bottom: 0,
                        width: '9600px',
                        right: '100%',
                        background: theme.palette.gray1.main,
                      },
                    }
                  : undefined
              }
            />
          )}
          <Grid
            item
            xs={7}
            sx={{
              backgroundColor: 'gray1.main',
            }}
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              gap="32px"
              pt="72px"
              id={faqSubsection.id}
              sx={
                index % 2 === 1
                  ? {
                      position: 'relative',
                      '&:before': {
                        content: '""',
                        position: 'absolute',
                        /* fill vertically */
                        top: 0,
                        bottom: 0,
                        width: '9600px',
                        right: '100%',
                        left: '100%',
                        marginTop: '-32px',
                        background: theme.palette.gray1.main,
                      },
                    }
                  : undefined
              }
            >
              <Box mt="-128px" position="absolute" />
              {faqSubsection.elements?.map((element: TrackedAccordionProps) => (
                <TrackedAccordion {...element} />
              ))}
            </Box>
          </Grid>
        </>
      ))}
    </SectionLayout>
  )
}

export default FaqSection

FaqSection.defaultProps = {
  sideBarItems: [
    {
      id: 'exchange',
      title: 'Exchange.',
      description: 'Common questions about joining the Overmoon 721 Fund.',
    },
    {
      id: 'ownership',
      title: 'Ownership.',
      description:
        'Find out more about owner experience with the Overmoon 721 Fund.',
    },
    {
      id: 'about-overmoon',
      title: 'About Overmoon.',
      description: 'Learn more about the Overmoon 721 Fund.',
    },
  ],
  faqSubsections: [
    {
      id: 'exchange',
      elements: [
        {
          summary: 'Is Overmoon active in every market?',
          description: `We are currently active in ${prettyPrintStates(
            'and'
          )}, with plans to expand to more states across the southeast and Sunbelt in 2024. We're selective with contributions to the Overmoon 721 Fund (the “Fund”), only taking houses that add value to the Fund’s investors. Submit your property information and we'll let you know if your house is currently a good fit!`,
        },
        {
          summary:
            "What's the advantage of investing in the Fund rather than just selling my property?",
          description: `Depending on how long you've owned your property, selling could lead to the loss of nearly 1/3 of your value to taxes, fees, and lost income. Investing in the Overmoon 721 Fund provides you the opportunity to defer taxes, earn passive income, maintain appreciation potential and avoid the process associated with a traditional sale.`,
        },
        {
          summary: `What's the advantage of owning with the Fund rather than using a property manager?`,
          description: `As both the owner and operator of the homes in its portfolio, we are motivated to make each home perform to its highest potential, beyond what we believe a property manager could achieve. This is how we simultaneously maximize the value of the Overmoon 721 Fund, while realizing our mission of becoming great hosts of vacation rental properties.`,
        },
        {
          summary: 'Who can contribute to the Overmoon 721 Fund?',
          description:
            'Rental property owners who own single-family rentals and qualify as accredited investors can contribute to the Fund. Contact us directly to learn more.',
        },
        {
          summary:
            'Once I invest in the Overmoon 721 Fund, do I still own my house?',
          description: `No, you'll own shares in the Overmoon 721 Fund, which will own the home as part of a portfolio of vacation rental properties. You'll no longer be responsible for any maintenance costs or tenant needs for the individual house, and will instead, own shares in the Fund.`,
        },
        {
          summary: 'Will the Fund buy my home with reservations on the books?',
          description:
            'Yes. We will work with you and your property manager to honor reservations on the books where possible.',
        },
        {
          summary:
            "What's the difference between investing in the Fund and buying shares of a REIT?",
          description: `Investing in the Fund is similar to selling your house and buying shares of a REIT. One major difference is that when you invest in the Overmoon 721 Fund, you have the opportunity to defer the tax consequences of a sale. Instead of buying shares with after-tax proceeds, you'll preserve value through a tax-deferred exchange for shares in the Fund and its diversified, managed portfolio of vacation homes.`,
        },
        {
          summary: 'Is the Overmoon 721 Fund a property management company?',
          children: (
            <Typography variant="p2">
              The Fund goes a step beyond property management. When you exchange
              your rental with us, we its ownership, meaning we are fully
              responsible for its property management, taxes, insurance,
              maintenance, and leasing. Your real estate investment becomes
              truly passive.
              <br />
              <br />
              In contrast, property managers handle the day-to-day operation of
              your property, but any major expense, liability, or legal action
              like an eviction would be ultimately your responsibility.
            </Typography>
          ),
        },
        {
          summary: 'What happens if I have a mortgage?',
          description:
            'The Overmoon 721 Fund will either pay off or assume your existing mortgage with your lender at the time of closing.',
        },
      ],
    },
    {
      id: 'ownership',
      elements: [
        {
          summary:
            'Will my income from the Fund be more or less than my current income?',
          description:
            'Income is calculated based on the appreciation of all the houses and the rental income of the portfolio. As an investor in the Fund, your shares will appreciate in value generally based on the appreciation of the portfolio, and your cash distributions will be based on the rental income collected (less operating expenses like insurance, property tax, maintenance reserves, etc.). Submit your property for an offer or contact us directly to find out more.',
        },
        {
          summary: 'How are distributions paid?',
          description: `The Fund distributes the portfolio's net operating cash flow after accounting for factors such as expenses, debt service, management fees, and capital expenditures on a monthly basis. Investors can elect to receive distributions or have distributions reinvested through the Distribution Reinvestment Program (DRIP). The Fund targets a ${
            OM_DEFAULT_DISTRIBUTION_FACTOR * 100
          }% annualized cash distribution yield (net of management fees).`,
        },
        {
          summary: 'How do I get liquidity?',
          description:
            'Most investors come to the Fund with a long-term vision to continue to receive income and participate in the appreciation potential of the properties. After three years, you can request to redeem shares for cash. Just like a traditional sale, redeeming your shares for cash is a taxable event.',
        },
        {
          summary:
            'Will I still receive depreciation from my ownership in the Fund?',
          description:
            'Yes, if your contributed property had an unclaimed depreciation balance, you will continue to receive depreciation.',
        },
        {
          summary: `How are the Overmoon 721 Fund’s properties valued?`,
          description: `The Fund intends to value its properties on a periodic basis, using leading third-party appraisals, broker's price opinions, and automated valuation models (AVMs). The Fund never profits from the valuation of a house. We'll never negotiate valuations–we created a system we believe is fair for everyone.`,
        },
        {
          summary: 'When can I redeem my shares?',
          description: `After an initial 3-year holding period and with 180 days' prior written notice, you may participate in the redemption program to redeem your shares, either in part or in full, for taxable cash.`,
        },
        {
          summary: 'If I redeem my shares, is that a taxable event?',
          description:
            'The redemption of any shares will be treated like the sale of any property, meaning regular capital gains and depreciation recapture taxes apply. One of the benefits to joining the Fund is the potential to spread out tax liabilities across years, which could result in lower costs over time.',
        },
        {
          summary: 'Will the Overmoon 721 Fund ever sell my property?',
          description:
            'The Fund is excited to be a long-term owner of every property that joins the portfolio. That being said, our fiduciary duty is to generate the best returns for the portfolio, and we will strategically cull the portfolio if an attractive opportunity presents itself.',
        },
        {
          summary:
            'I want to pass my properties on to an heir. How does that work with the Fund?',
          description:
            'Your ownership interest is treated much the same as a typical rental property – your heirs will generally receive a step-up in basis upon inheritance. We believe the flexibility afforded by owning shares in a portfolio of assets rather than one illiquid property makes the Fund an attractive estate planning instrument. However, we recommend that you consult with your personal tax advisor and/or attorney to understand your own unique situation.',
        },
        {
          summary:
            'After I sell my shares, can I utilize a 1031 Exchange or other tax-deferred solution on proceeds?',
          description:
            'No. Once a property has been exchanged for an ownership interest in the Fund, the proceeds from its sale cannot be applied to another like-kind exchange.',
        },
      ],
    },
    {
      id: 'about-overmoon',
      elements: [
        {
          summary: 'What is Overmoon?',
          children: (
            <Typography variant="p2">
              Founded in 2020, Overmoon is a real estate technology company
              creating a portfolio of vacation homes the world can trust with
              its vacations.
              <br />
              <br />
              With the Overmoon 721 Fund you can exchange your property for
              shares in a managed portfolio of vacation homes without triggering
              capital gains or depreciation recapture taxes. The Fund takes care
              of all expenses and responsibilities, while you continue to
              participate in income and appreciation as a passive investor.
            </Typography>
          ),
        },
        {
          summary: 'How does the Fund earn income for me?',
          children: (
            <Typography variant="p2">
              For income, we collect rent from these vacation homes, then pay
              for all associated expenses, including property taxes, property
              management, insurance, and maintenance. We also assess a 1% asset
              management fee. The net income is paid out to our investors in the
              form of a cash distribution. The Fund intends for distributions to
              be paid monthly via direct deposit.
            </Typography>
          ),
        },
        {
          summary: 'What is the 721 tax exchange?',
          children: (
            <Typography variant="p2">
              Overmoon has created the Fund to allow owners of investment
              properties to take advantage of a tax-deferred exchange. Section
              721 of the Internal Revenue Code provides that no gain or loss is
              recognized when property is contributed into a partnership in
              exchange for interest, or ownership, in the partnership. In other
              words, The Fund enables investors like you to exchange your rental
              property for shares in{' '}
              <TrackedLink
                to={PORTFOLIO_PATH}
                sx={{ color: '#D14261!important' }}
              >
                our portfolio
              </TrackedLink>{' '}
              without triggering a taxable event.
            </Typography>
          ),
        },
        {
          summary: 'When I exchange my house for shares, what do I own?',
          children: (
            <Typography variant="p2">
              You will receive shares in the Fund, which owns a{' '}
              <TrackedLink
                to={PORTFOLIO_PATH}
                sx={{ color: '#D14261!important' }}
              >
                portfolio of professionally managed rental properties.
              </TrackedLink>{' '}
            </Typography>
          ),
        },
        {
          summary: 'Are there fees associated with the Fund?',
          description:
            'Like many funds, there are certain fees and costs associated with an investment in the Fund. Such fees are generally associated with the management and operation of the Fund and its portfolio of properties. Such fees include acquisition fees, asset management fees, financing fees, property management fees, disposition fees and technology services fees. We believe these fees are reasonable and competitive in the market in terms of both the types and amounts of such fees.',
        },
        {
          summary: 'What happens to the Fund in a recession?',
          description:
            'The Fund offers risk diversification, both from a geographic and tenant perspective. While it is impossible to predict how your investment in the Fund would fare compared to a traditional rental property, we believe owning many rental properties in numerous markets instead of one reduces exposure to local economic downturns. This is an attractive feature to most owners.',
        },
      ],
    },
  ],
}
